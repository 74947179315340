import axios from 'axios';
import Cookies from 'universal-cookie/cjs';
import moment from 'moment';
import {
  getCarParks,
  getCarParkOptions,
  getAllParkingService,
  getAllCarTypes,
  getCustomerByPhone,
  getPaymentMethods,
  getPPCards,
  getRegistrationMarks,
} from './generalHelper';
import { getCustomerLocations } from './selectOptions';
import { activateCardTypeOptions } from '../containers/Custom/Car/constants';
import {
  customerTitleOptions,
  customerStatusOptions,
  customerLanguageOptions,
} from '../containers/Custom/Customer/constants';
import { paymentStatusOptions } from '../containers/Custom/Payment/constants';
import { ppCardStatus } from '../containers/Custom/PPCard/constants';
import { statusOptions } from '../containers/Custom/Transaction/constants';

const cookies = new Cookies();

export async function createRecord(url, body) {
  try {
    await axios.post(url, body, { headers: { token: cookies.get('token') } });
    if (!alert('New record successfully created')) {
      window.location.reload();
    }
  } catch (e) {
    const { response = {} } = e || {};
    const { data = {} } = response || {};
    const { error = '' } = data || {};
    if (error && error === 'User already have a confirmed waiting') {
      alert('Duplicate Records.');
      return;
    }
    alert('Something went wrong. Please make sure to fill in all the information');
    console.log(e);
  }
}

export async function updateRecord(url, body, showRowErrorMessage = false) {
  try {
    await axios.put(url, body, { headers: { token: cookies.get('token') } });
    if (!alert('Record successfully updated')) {
      window.location.reload();
    }
  } catch (e) {
    const { response = {} } = e || {};
    const { data = {} } = response || {};
    const { error = '' } = data || {};
    if (error && error === 'User already have a confirmed waiting') {
      alert('Duplicate Records.');
      return;
    }

    console.log(e);

    if (showRowErrorMessage) {
      alert(error);
      return;
    }
    alert('Something went wrong. Please make sure to fill in all the information');
  }
}

export async function deleteRecord(url) {
  try {
    await axios.delete(url, { headers: { token: cookies.get('token') } });
    if (!alert('Record successfully deleted')) {
      window.location.reload();
    }
  } catch (e) {
    alert('Something went wrong.');
    console.log(e);
  }
}

export async function bookingSelectOptions() {
  const [{ carParkOptions }, { allPPCards }] = await Promise.all([
    getCarParkOptions(),
    getPPCards(),
  ]);

  return {
    carParkCode: carParkOptions,
    ppCardNumber: allPPCards,
  };
}

export async function bookingSubmitData(data) {
  const {
    carParkCode,
    parkingPlan,
    registrationMark: regMarkSelected,
    customer,
    ppCardNumber,
    type: typeValue,
    ...rest
  } = data;

  const { value: CarParkId } = carParkCode || {};
  const { value: ParkingPlanId } = parkingPlan || {};
  const { value: CarId } = regMarkSelected || {};
  const { value: customerPhone } = customer || {};
  const { value: PPCardId } = ppCardNumber || {};

  const CustomerId = await getCustomerByPhone(customerPhone);

  return {
    CarParkId,
    ParkingPlanId,
    CarId,
    CustomerId,
    PPCardId,
    ...rest,
  };
}

export async function carSelectOptions() {
  const { carTypes } = await getAllCarTypes();

  return {
    carType: carTypes,
    activateCardType: activateCardTypeOptions,
  };
}

export async function carSubmitData(data) {
  const { customerPhone: { value: customerPhone } = {}, carType, activateCardType, ...rest } = data;

  const { value: CarTypeId } = carType || {};
  const { value: activateCard } = activateCardType || {};

  const CustomerId = customerPhone ? await getCustomerByPhone(customerPhone) : undefined;

  return {
    CustomerId,
    CarTypeId,
    activateCard,
    ...rest,
  };
}

export async function customerSelectOptions() {
  const { allRegionOptions } = await getCustomerLocations();

  return {
    title: customerTitleOptions,
    status: customerStatusOptions,
    language: customerLanguageOptions,
    region: allRegionOptions,
    district: [],
  };
}

export async function customerSubmitData(data) {
  const { title, language, status, district, majorCustomerId, region, ...rest } = data;
  const { value: titleValue } = title || {};
  const { value: languageValue } = language || {};
  const { value: statusValue } = status || {};
  const { value: LocationId } = district || {};

  return {
    title: titleValue,
    language: languageValue,
    status: statusValue,
    MajorCustomerId: majorCustomerId,
    LocationId,
    ...rest,
  };
}

export async function parkingPlanSelectOptions() {
  const [{ carParkOptions }, { allParkingService }, { carTypes }] = await Promise.all([
    getCarParkOptions(),
    getAllParkingService(),
    getAllCarTypes(),
  ]);

  return {
    carParkCode: carParkOptions,
    parkingService: allParkingService,
    carType: carTypes,
  };
}

export async function parkingPlanSubmitData(data) {
  const { carParkCode, parkingService, carType, ...rest } = data;
  const { value: CarParkId } = carParkCode || {};
  const { value: ParkingServiceId } = parkingService || {};
  const { value: CarTypeId } = carType || {};

  return {
    CarParkId,
    ParkingServiceId,
    CarTypeId,
    ...rest,
  };
}

export async function paymentSelectOptions() {
  const { allPaymentMethods } = await getPaymentMethods();

  return {
    paymentMethod: allPaymentMethods,
    status: paymentStatusOptions,
  };
}

export async function paymentSubmitData(data) {
  const { status, paymentMethod, ...rest } = data;

  const { value: statusId } = status || {};
  const { value: PaymentMethodId } = paymentMethod || {};

  return {
    status: statusId,
    PaymentMethodId,
    ...rest,
  };
}

export async function ppCardSelectOptions() {
  const { allPPCards } = await getPPCards();

  return {
    cardNumber: allPPCards,
    status: ppCardStatus,
  };
}

export async function ppCardSubmitData(data) {
  const { id, cardNumber, status } = data;
  const { value: statusValue } = status || {};

  return {
    id,
    cardNumber,
    status: statusValue,
  };
}

export async function transactionSelectOptions() {
  const [{ carParkOptions }, { allPaymentMethods }] = await Promise.all([
    getCarParks(),
    getPaymentMethods(),
  ]);

  return {
    carParkCode: carParkOptions,
    paymentMethod: allPaymentMethods,
    status: statusOptions,
  };
}

export async function transactionSubmitData(data, submitType) {
  const { carParkCode, paymentMethod, status, transactionTime, ...rest } = data;

  const { value: CarParkId } = carParkCode || {};
  const { value: PaymentMethodId } = paymentMethod || {};
  const { value: statusValue } = status || {};

  const resultObj = {
    PaymentMethodId,
    CarParkId,
    status: statusValue,
    ...rest,
  };

  if (submitType === 'add' && !transactionTime) {
    resultObj.transactionTime = moment().utcOffset(8).format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
  }

  return resultObj;
}

export async function adminSubmitData(data) {
  const {
    name,
    isEnable,
    // ...rest
  } = data;

  // const { value: CarParkId } = carParkCode || {};

  return {
    name,
    isEnable,
    // ...rest
  };
}

export function getStaticInfoName(module) {
  if (module === '/ourCompany') {
    return 'OurCompany';
  }

  if (module === '/homePageAndPages') {
    return 'homePageAndPages';
  }
  return '';
}
