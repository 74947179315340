import React, { useEffect, useRef, useState } from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { Table as AntTable, DatePicker, Radio, Collapse, Checkbox, Modal } from 'antd';
import 'antd/dist/antd.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import moment from 'moment';
// import JsPDF from 'jspdf';
// import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes, { array } from 'prop-types';
import Cookies from 'universal-cookie/cjs';
import AsyncSelect from 'react-select/async';
import { generateTableColumns, cleanDefaultTableData, useRefund } from '../../../util/tableHelper';
import { capitalize, loadCustomersOptions } from '../../../util/generalHelper';
import useRenderOperationReport from './useRenderOperationReport';

const cookies = new Cookies();

function DefaultTable({
  getAllRecords,
  details,
  edit,
  customSchema = [],
  groupedHeadColumns = undefined,
  customCleanData = undefined,
  customColumn = undefined, // column that the returned table schema does NOT include
  reworkedColumn = undefined, // column that the returned table schema includes, with ADD-ON needed
  filterBy = [],
  // pdfDocTitle = undefined,
  // customPdfHead = undefined,

  customCsvColumns = undefined,
  csvFileName,
  additionalCSVs = [],
  collapseFilter,
  defaultSorting = {},
  refund,
  reportType,
  canExport,
}) {
  const history = useHistory();
  const location = useLocation();
  const { page: pageFromQuery = 1, ...queryFilters } = queryString.parse(location.search);

  const { Panel } = Collapse;

  const animatedComponents = makeAnimated();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    current: parseInt(pageFromQuery),
  });
  const [filter, setFilter] = useState({ ...queryFilters });
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
  });

  // const [doc, setDoc] = useState();

  const [csvData, setCsvData] = useState([]);

  const [additionalCsvData, setAddtionalCsvData] = useState([]);
  const [recordCount, setRecordCount] = useState(0);

  const { renderRefundButton, RefundableTable } = useRefund({
    isLoading,
    setIsLoading,
    cookies,
  });

  const wrapExcelString = (str) => {
    if (str === false) {
      return `=""${str}""`;
    }

    if (str === null || (str && str.length === 0)) {
      return `=""N/A""`;
    }
    return `=""${str}""`;
  };

  const { RenderOperationReportButton } = useRenderOperationReport({
    getAllRecords,
    filter,
    cookies,
    wrapExcelString,
    sortBy,
    pagination,
  });

  useEffect(() => {
    if (csvFileName && Array.isArray(dataSource) && dataSource.length > 0) {
      let head;
      if (customCsvColumns) {
        head = customCsvColumns;
      } else {
        head = customSchema
          .filter((item) => item !== 'id')
          .map((column) => capitalize(column.replace(/([A-Z])/g, ' $1').trim()));
      }

      const allRecords = dataSource.map(({ id, ...rest }) =>
        Object.values(rest).map((v) => wrapExcelString(v))
      );

      setCsvData([head, ...allRecords]);
    }
  }, [csvFileName, dataSource]);

  const fetch = (params = {}) => {
    setIsLoading(true);

    const updatedFilter = Object.entries(filter).reduce((output, [key, value]) => {
      if (value !== '' && !Array.isArray(value)) {
        output[key] = value;
      }
      if (Array.isArray(value) && value.length > 0) {
        if (value.filter((e) => e).length === 0) return output;
        output[key] = value.filter((e) => e).toString();
      }
      if (value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
        output[key] = value.value;
      }

      return output;
    }, {});

    const {
      pagination,
      sortField = defaultSorting.sortField || 'id',
      sortOrder = defaultSorting.sortOrder || 'descend',
    } = params;

    const updatedParams = {
      ...updatedFilter,
      ...pagination,
      // sortField,
      // sortOrder,
    };

    // Convert Antd Table "pagination.current" to API query param "page"
    updatedParams.page = updatedParams.current;
    delete updatedParams.current;

    axios
      .get(getAllRecords, {
        params: updatedParams,
        headers: { token: cookies.get('token') },
      })
      .then(async (response) => {
        // console.log(response);
        let cleaned;
        setRecordCount(response.data.count || 0);
        if (customCleanData === undefined) {
          cleaned = cleanDefaultTableData(response);
          setSchema(cleaned.cleanedSchema);
        } else if (customCleanData && reworkedColumn) {
          cleaned = await customCleanData(response, customColumn, reworkedColumn);
          customSchema && setSchema(customSchema);
        } else {
          cleaned = await customCleanData(response, customColumn);
          customSchema && setSchema(customSchema);
        }

        if (Array.isArray(additionalCSVs) && additionalCSVs.length > 0) {
          const cleanedAddtionalCsvData = await Promise.all(
            additionalCSVs.map((addtionalCsv) => {
              const cleanedAdditionalCsv = {
                ...addtionalCsv,
                data: [],
              };
              if (typeof cleanedAdditionalCsv.cleanData === 'function')
                cleanedAdditionalCsv.data = cleanedAdditionalCsv.cleanData(response);
              return cleanedAdditionalCsv;
            })
          );
          setAddtionalCsvData(cleanedAddtionalCsvData);
        }

        setDataSource(cleaned.newDataSource);
        setPagination({ ...params.pagination, total: response.data.count });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          const errorType = error.response.status.toString()[0];
          if (errorType === '5') {
            alert('Filter not available at the moment');
          }
        }
        setDataSource([]);
        setIsLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const page = filters ? pagination.current : 1;
    const { columnKey, order } = sorter || {};

    let search = `?page=${page}`;
    if (filter) {
      Object.keys(filter).forEach((filterKey) => {
        let value = filter[filterKey];

        if (typeof filter[filterKey] === 'object') value = filter[filterKey].value;
        if (Array.isArray(filter[filterKey])) value = filter[filterKey].toString();
        if (!value) return '';
        search += `&${filterKey}=${value}`;
      });
    }

    history.replace({
      pathname: location.pathname,
      search,
    });

    setSortBy({ page: pagination.current, columnKey, order });

    fetch({
      pagination: { current: page },
      sortField: columnKey,
      sortOrder: order,
    });
  };

  const renderDetailsButton = (id) => (
    <button
      type="button"
      className="btn btn-primary btn-xs mr-3"
      onClick={() => details && details(id)}
    >
      Details
    </button>
  );

  const renderEditButton = (id) => (
    <button type="button" className="btn btn-primary btn-xs mr-3" onClick={() => edit && edit(id)}>
      Edit
    </button>
  );

  const tableSchema = generateTableColumns(
    schema,
    details ? (id) => renderDetailsButton(id) : undefined,
    edit ? (id) => renderEditButton(id) : undefined,
    refund ? (id) => renderRefundButton(id) : undefined
  );

  const handleFilter = (e) => {
    e.persist();
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleTableFilter = () => {
    handleTableChange(pagination, filter, sortBy);

    // const newPdfDoc = new JsPDF({
    //   orientation: 'l',
    //   unit: 'pt',
    //   format: 'a3'
    // });

    // setDoc(newPdfDoc);
  };

  const onChangeDate = (date, dateString, value) => {
    setFilter({ ...filter, [value]: dateString });
  };

  const handleSelect = (selected, value) => {
    setFilter({ ...filter, [value]: selected });
  };

  useEffect(() => {
    if (isInitialized && Object.keys(filter).length === 0) {
      handleTableChange(pagination);
    }
  }, [filter]);

  useEffect(() => {
    fetch({ pagination: { current: parseInt(pageFromQuery) } });
    setIsInitialized(true);
    // if (pdfDocTitle) {
    //   JsPDF.autoTableSetDefaults({
    //     headStyles: { fillColor: 'fafafa', textColor: '252525' }
    //   });
    // }
  }, []);

  const renderFilterField = ({ title, value, type, options, loadOptions }) => {
    function getDateDefaultValue() {
      const dayFormat = 'YYYY-MM-DD';
      if (filter[value]) {
        return moment(filter[value], dayFormat);
      }
      if (value === 'bookingEndDateFrom') {
        const startDay = moment().startOf('month').format(dayFormat);
        return moment(startDay, dayFormat);
      }
      if (value === 'bookingEndDateTo') {
        const endDay = moment().endOf('month').format(dayFormat);
        return moment(endDay, dayFormat);
      }
      return undefined;
    }

    function getRadioValue() {
      if (filter[value] === false) {
        return false;
      }
      return filter[value] || '';
    }

    function isExclusiveOptionSelected(option) {
      if (!Array.isArray(option.exclusive) || option.exclusive.length === 0) return false;
      const result = option.exclusive.some(({ key: exclusiveKey, value: exclusiveValue }) => {
        const stringResult =
          typeof filter[exclusiveKey] === 'string' &&
          filter[exclusiveKey].split(',').indexOf(exclusiveValue) !== -1;
        const arrayResult =
          Array.isArray(filter[exclusiveKey]) &&
          filter[exclusiveKey].indexOf(exclusiveValue) !== -1;
        return !!filter[exclusiveKey] && (stringResult || arrayResult);
      });
      return result;
    }

    switch (type) {
      case 'checkbox':
        return (
          <Col
            md={6}
            className="mb-4"
            key={value}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Label>{title}</Label>
            <Checkbox.Group
              style={{ marginTop: -10 }}
              onChange={(e) => {
                if (e.findIndex((e) => e === '') > 0) {
                  handleSelect([''], value);
                  return;
                }
                if (e.includes('') && e.length > 1) {
                  handleSelect(
                    e.filter((v) => v),
                    value
                  );
                  return;
                }
                if (e.length === 0) {
                  handleSelect([''], value);
                  return;
                }
                handleSelect(e, value);
              }}
              value={filter[value] || ['']}
            >
              {options &&
                options.length > 0 &&
                options.map(({ value: checkBoxValue, label, exclusive = [] }) => (
                  <Checkbox
                    style={{ marginTop: 10, marginLeft: 0 }}
                    value={checkBoxValue}
                    disabled={isExclusiveOptionSelected({ exclusive, value: checkBoxValue })}
                  >
                    {label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Col>
        );
      case 'radio':
        return (
          <Col
            md={6}
            className="mb-4"
            key={value}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Label>{title}</Label>
            <Radio.Group
              style={{ marginTop: -10 }}
              onChange={(e) => handleSelect(e.target.value, value)}
              value={getRadioValue()}
              defaultValue={options[0].value}
            >
              {options &&
                options.length > 0 &&
                options.map(({ value: v, label, exclusive = [] }) => (
                  <Radio
                    style={{ marginTop: 10 }}
                    value={v}
                    disabled={isExclusiveOptionSelected({ exclusive, value: v })}
                  >
                    {label}
                  </Radio>
                ))}
            </Radio.Group>
          </Col>
        );
      case 'datePicker':
        return (
          <Col md={6} className="mb-4" key={value}>
            <div className="d-flex flex-column">
              <Label>{title}</Label>
              <DatePicker
                value={getDateDefaultValue()}
                className="mb-4"
                onChange={(date, dateString) => onChangeDate(date, dateString, value)}
              />
            </div>
          </Col>
        );
      case 'dropdown':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Select
              value={filter[value] || null}
              menuPlacement="auto"
              menuPosition="fixed"
              options={options}
              onChange={(selected) => handleSelect(selected, value)}
            />
          </Col>
        );
      case 'asyncDropdown':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <AsyncSelect
              value={filter[value] || null}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(selected) => handleSelect(selected, value)}
              defaultOptions
              loadOptions={loadOptions}
            />
          </Col>
        );
      case 'multiSelect':
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={filter[value] || []}
              isMulti
              options={options}
              onChange={(selected) => handleSelect(selected, value)}
            />
          </Col>
        );
      default:
        return (
          <Col md={6} className="mb-4" key={value}>
            <Label>{title}</Label>
            <Input type="text" name={value} value={filter[value] || ''} onChange={handleFilter} />
          </Col>
        );
    }
  };

  return (
    <>
      {filterBy.length > 0 && (
        <div className="mb-4">
          {collapseFilter ? (
            <Collapse defaultActiveKey={['1']} style={{ marginBottom: 20 }}>
              <Panel header="Search" key="1">
                <Row>{filterBy.map((item) => renderFilterField(item))}</Row>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={handleTableFilter}
                >
                  Search
                </button>
                <button type="button" className="btn btn-link btn-sm" onClick={() => setFilter({})}>
                  Reset
                </button>
              </Panel>
            </Collapse>
          ) : (
            <>
              <p>Filter by:</p>
              <Row>{filterBy.map((item) => renderFilterField(item))}</Row>
              <button type="button" className="btn btn-primary btn-sm" onClick={handleTableFilter}>
                Search
              </button>
              <button type="button" className="btn btn-link btn-sm" onClick={() => setFilter({})}>
                Reset
              </button>
            </>
          )}
        </div>
      )}

      {canExport && csvFileName && Array.isArray(dataSource) && dataSource.length > 0 && (
        <>
          <CSVLink data={csvData} filename={csvFileName} style={{ float: 'right' }}>
            Download CSV file
          </CSVLink>
          <br />
          <br />
        </>
      )}

      {canExport && Array.isArray(reportType) && RenderOperationReportButton()}

      {Array.isArray(additionalCsvData) &&
        additionalCsvData.length > 0 &&
        additionalCsvData.map((additionalCSV) => {
          const { data, filename, buttonText } = additionalCSV;
          if (!Array.isArray(data) || data.length <= 1 || !filename || !buttonText) return null;
          return (
            <>
              <CSVLink data={data} filename={filename} style={{ float: 'right' }}>
                {buttonText}
              </CSVLink>
              <br />
              <br />
            </>
          );
        })}

      {/* {pdfDocTitle && Array.isArray(dataSource) && dataSource.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 15 }}>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => doc.save(`WP_${pdfDocTitle.replace(' ', '_')}_Report.pdf`)}
          >
            Download PDF
          </button>
        </div>
      )} */}

      <AntTable
        className="w-100"
        columns={groupedHeadColumns || tableSchema}
        rowKey="id"
        dataSource={dataSource}
        pagination={{ ...pagination, position: 'both', showQuickJumper: true }}
        loading={isLoading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        bordered={!!groupedHeadColumns}
      />

      {recordCount > 0 && (
        <p>
          <b>
            Showing&nbsp;
            {((pagination.current || 1) - 1) * (pagination.pageSize || 100) + 1}
            &nbsp; to&nbsp;
            {Math.min((pagination.current || 1) * (pagination.pageSize || 100), recordCount)}
            &nbsp; of&nbsp;
            {recordCount}
            &nbsp; records.
          </b>
        </p>
      )}

      {refund && RefundableTable()}
    </>
  );
}

DefaultTable.propTypes = {
  getAllRecords: PropTypes.string,
  details: PropTypes.func,
  edit: PropTypes.func,
  customSchema: PropTypes.arrayOf(PropTypes.string),
  groupedHeadColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      children: PropTypes.array,
    })
  ),
  customCleanData: PropTypes.func,
  customColumn: PropTypes.func, // column that the returned table schema does NOT include
  reworkedColumn: PropTypes.func, // column that the returned table schema includes, with ADD-ON needed
  filterBy: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  customCsvColumns: PropTypes.arrayOf(PropTypes.string),
  csvFileName: PropTypes.string,
  additionalCSVs: PropTypes.arrayOf(
    PropTypes.shape({
      cleanData: PropTypes.func,
      buttonText: PropTypes.string,
      filename: PropTypes.string,
    })
  ),
};

export default DefaultTable;
