import _, { last } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { Table as AntTable, Modal } from 'antd';
import React, { useState } from 'react';
import { capitalize, getPaymentMethods, getPPCards, handleData } from './generalHelper';

const { REACT_APP_API_DOMAIN } = process.env;

export function parseDate(dateValue, format = 'DD MMM YYYY') {
  if (!dateValue || dateValue === 'N/A') return 'N/A';
  return moment(dateValue).utcOffset(0).startOf('day').format(format);
}

export function parseTime(dateValue, format = 'HH:mm', utcOffset = 0) {
  return dateValue ? moment(dateValue).utcOffset(utcOffset).format(format) : 'N/A';
}

export function cleanDefaultTableData(response) {
  const resSchema = response.data.schema;
  const dataSource = response.data.data ? response.data.data : [];
  let cleanedSchema = [];

  if (resSchema) {
    cleanedSchema = resSchema.filter(({ name }) => name !== 'deletedAt');
    cleanedSchema = cleanedSchema.map(({ name }) => name);
  }

  const isDateType = resSchema.filter(
    (item) => item.type === 'DATE' && item.name !== 'deletedAt' && item.name !== 'password'
  );
  const dateTypeData = isDateType.map(({ name }) => name);
  const newDataSource = [];

  const isBooleanType = resSchema.filter(({ type }) => type === 'BOOLEAN');
  const booleanTypeData = isBooleanType.map(({ name }) => name);

  dataSource &&
    dataSource.forEach((data) => {
      newDataSource.push(
        Object.entries(data).reduce((output, [key, value]) => {
          if (key !== 'deletedAt') {
            output[key] = value;
          }

          if (dateTypeData.includes(key)) {
            output[key] = new Date(value).toLocaleDateString();
          }

          if (booleanTypeData.includes(key)) {
            if (value === true) {
              output[key] = 'Yes';
            } else {
              output[key] = 'No';
            }
          }

          if (key !== 'deletedAt' && (value === '' || value === undefined || value === null)) {
            output[key] = 'N/A';
          }
          return output;
        }, {})
      );
    });

  return { cleanedSchema, newDataSource };
}

export function generateTableColumns(schema, detailsButton, editButton, refundButton) {
  const columns = schema.map((dataIndex) => {
    const title = _.lowerCase(dataIndex)
      .split(' ')
      .map((word) => _.capitalize(word))
      .join(' ');
    // const key = dataIndex;

    return {
      title,
      dataIndex,
      sorter: false,
      render: (text) => {
        if (title.includes('Remark')) {
          return <span dangerouslySetInnerHTML={{ __html: text }} />;
        }

        if (dataIndex === 'emailContent') {
          console.log(text.includes('</p>'), title);
        }

        if (text && typeof text === 'string' && text.includes('</p>')) {
          return (
            <div
              style={{
                maxWidth: 300,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          );
        }
        return text;
      },
    };
  });

  if (detailsButton) {
    const details = {
      title: 'Details',
      dataIndex: 'id', // fills the param in detailsButton
      key: 'idForDetails',
      render: detailsButton,
    };
    columns.push(details);
  }

  if (editButton) {
    const edit = {
      title: 'Edit',
      dataIndex: 'id', // fills the param in editButton
      key: 'idForEdit',
      render: editButton,
    };
    columns.push(edit);
  }

  if (refundButton) {
    const refund = {
      title: 'Refund',
      dataIndex: 'paymentId', // fills the param in editButton
      key: 'idForRefund',
      render: (id, record, index) => refundButton(id, record, index),
    };
    columns.push(refund);
  }

  return columns;
}

export function cleanAnnouncementTableData(resource) {
  const newDataSource = resource.data.data
    .filter((e) => e.type === 'announcement')
    .map((e) => {
      const {
        id,
        title,
        chineseTitle,
        content,
        chineseContent,
        status,
        startDate,
        expiryDate,
        createdAt,
        urlLink,
        chineseUrlLink,
      } = e;

      return {
        id,
        title,
        chineseTitle,
        content,
        chineseContent,
        status,
        startDate: startDate ? moment(startDate).format('MM/DD/YYYY') : 'N/A',
        expiryDate: expiryDate ? moment(expiryDate).format('MM/DD/YYYY') : 'N/A',
        createdAt: createdAt ? moment(createdAt).format('MM/DD/YYYY') : 'N/A',
        urlLink,
        chineseUrlLink,
      };
    });

  return { newDataSource };
}

export function cleanWaitingListTableData(resource, customColumn) {
  const newDataSource = resource.data.data.map((e) => {
    const {
      id,
      createdAt,
      reference,
      CarPark = {},
      Customer = {},
      Car = {},
      ParkingPlan = {},
      status,
      remarks,
      Booking = {},
      priority,
      BookingId,
      manualInputDate,
    } = e;

    const { code = '' } = CarPark || {};
    const { firstName = '', lastName = '', phone = '' } = Customer || {};
    const { registrationMark = '' } = Car || {};
    const { subTitle = '' } = ParkingPlan || {};
    const { accountCode = '' } = Booking || {};

    return {
      id,
      ID: id,
      reference,
      code,
      customerName: `${firstName} ${lastName}`,
      phone,
      registrationMark,
      parkingService: subTitle,
      status,
      remarks,
      Booking: status === 'paid' && accountCode ? customColumn(accountCode) : 'N/A',
      BookingId: BookingId || 'N/A',
      priority,
      createdAt: createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm') : 'N/A',
      manualInputDate: manualInputDate || 'N/A',
    };
  });

  return { newDataSource };
}

export function cleanNewsTableData(resource) {
  const newDataSource = resource.data.data
    .filter((e) => e.type === 'news')
    .map((e) => {
      const {
        id,
        title,
        chineseTitle,
        abstract,
        chineseAbstract,
        content,
        chineseContent,
        status,
        startDate,
        expiryDate,
        createdAt,
      } = e;

      return {
        id,
        title,
        chineseTitle,
        abstract,
        chineseAbstract,
        content,
        chineseContent,
        status,
        startDate: startDate ? moment(startDate).format('MM/DD/YYYY') : 'N/A',
        expiryDate: expiryDate ? moment(expiryDate).format('MM/DD/YYYY') : 'N/A',
        createdAt: createdAt ? moment(createdAt).format('MM/DD/YYYY') : 'N/A',
      };
    });

  return { newDataSource };
}

export function cleanCarTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      originalId,
      registrationMark,
      isActivate: isActivateValue,
      isEnable: isEnableValue,
      createdAt: createdAtValue,
      CarType,
      Customer,
    } = item;

    const { name: carType = 'N/A' } = CarType || {};
    const { phone: customerPhone = 'N/A' } = Customer || {};

    const isActivate = isActivateValue ? 'Yes' : 'No';
    const isEnable = isEnableValue ? 'Yes' : 'No';
    const createdAt = parseDate(createdAtValue, undefined, 8);

    return {
      id,
      originalId,
      registrationMark,
      isActivate,
      isEnable,
      createdAt,
      carType,
      customerPhone,
    };
  });

  return { newDataSource };
}

export function cleanCarParkTableData(resource, customColumn) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      facilityNo,
      code,
      name,
      chineseName,
      isIMonthly: isIMonthlyValue,
      onlineShow: onlineShowValue,
      updatedAt: updatedAtValue,
      Location,
      PaymentMethods = [],
      displayPaymentMethods,
    } = item;

    const isIMonthly = isIMonthlyValue ? 'Yes' : 'No';
    const onlineShow = onlineShowValue ? 'Yes' : 'No';
    const updatedAt = parseDate(updatedAtValue, undefined, 8);

    const { region, district } = Location;
    const location = `${region} ${district}`;

    return {
      id,
      facilityNo,
      code,
      name,
      chineseName,
      isIMonthly,
      onlineShow,
      updatedAt,
      location,
      'Payment Link Methods': customColumn(id, PaymentMethods),
      displayPaymentMethods,
    };
  });

  return { newDataSource };
}

export function cleanCustomerTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      title,
      lastName,
      chineseLastName = 'N/A',
      email = 'N/A',
      phone,
      status = 'N/A',
      createdAt: createdAtValue,
      isReceiveSms,
    } = item;

    const createdAt = parseDate(createdAtValue, undefined, 8);

    const ReceiveSms = isReceiveSms ? 'Yes' : 'No';

    return {
      id,
      title,
      lastName,
      chineseLastName,
      email,
      phone,
      status,
      createdAt,
      ReceiveSms,
    };
  });

  return { newDataSource };
}

export async function changeBookingStatus(record) {
  const { id, status } = record;
  if (!id || !status) {
    alert('Error occured');
    return;
  }

  try {
    await axios.put(`${REACT_APP_API_DOMAIN}/booking/${id}`, { status });
    if (window.confirm('Booking status successfully updated!')) {
      window.location.reload();
    }
  } catch {
    alert('Error occured');
  }
}

export async function cleanBookingTableData(resource, customColumn = null, reworkedColumn) {
  const { allPPCards = [] } = (await getPPCards()) || {};

  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      ParkingPlan,
      Customer,
      PPCardId,
      Car,
      validTo,
      Order,
      status = 'N/A',
      accountCode = '',
      CarParkId,
    } = item;
    const { CarPark, ParkingService, subTitle } = ParkingPlan || {};
    const { code: carParkCode } = CarPark || {};
    const { type } = ParkingService || {};
    const {
      // lastName: customerName,
      phone,
    } = Customer || {};
    const { registrationMark } = Car || {};
    const { Payment } = Order || {};
    const { updatedAt } = Payment || {};

    const parkingServiceDesc = ` ${type} | ${subTitle} `;
    const expiryDate = parseDate(validTo, undefined, 0);
    const lastPaymentTime = parseTime(updatedAt, undefined, 8);

    const { label: ppCardNumber = 'N/A' } =
      allPPCards.find(({ value }) => value === PPCardId) || {};

    return {
      id,
      carParkCode,
      phone: handleData(phone),
      ppCardNumber,
      registrationMark,
      parkingServiceDesc,
      expiryDate,
      lastPaymentTime,
      status: customColumn ? status : reworkedColumn(id, status),
      accountCode,
      CarParkId,
    };
  });

  return { newDataSource };
}

export async function cleanLastBookingTableData(resource, customColumn, reworkedColumn) {
  const { newDataSource } = await cleanBookingTableData(resource, true);

  const updatedDataSource = newDataSource.map((item) => {
    const { id, status, reference, CarParkId } = item;

    return {
      ...item,
      actions: customColumn(id, status, reference, CarParkId),
      status: reworkedColumn(id, status),
    };
  });

  return { newDataSource: updatedDataSource };
}

export async function cleanNotificationTemplateTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const { id, name, codeName, cc, bcc, emailTitle, chineseEmailTitle } = item;

    return {
      id,
      name,
      codeName,
      cc,
      bcc,
      emailTitle,
      chineseEmailTitle,
    };
  });

  return { newDataSource };
}

export function cleanOrderTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      originalId = 'N/A',
      reference,
      type,
      status,
      createdAt: createdAtValue,
      updatedAt: updatedAtValue,
      Bookings: [booking],
    } = item;
    const {
      Customer,
      Car,
      ParkingPlan,
      validFrom: validFromValue,
      validTo: validToValue,
    } = booking || {};
    const { CarPark } = ParkingPlan || {};
    const { code: carParkCode = 'N/A' } = CarPark || {};
    const { phone: customerPhone = 'N/A' } = Customer || {};
    const { registrationMark = 'N/A' } = Car || {};

    const createdAt = parseDate(createdAtValue, undefined, 8);
    const updatedAt = parseDate(updatedAtValue, undefined, 8);
    const validFrom = parseDate(validFromValue, undefined, 8);
    const validTo = parseDate(validToValue, undefined, 8);

    return {
      id,
      originalId,
      reference,
      type: capitalize(type),
      status: capitalize(status),
      createdAt,
      updatedAt,
      customerPhone,
      registrationMark,
      carParkCode,
      validFrom,
      validTo,
    };
  });

  return { newDataSource };
}

export function cleanParkingPlanTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      CarPark,
      ParkingService,
      CarType,
      subTitle = 'N/A',
      quota = 0,
      amount = 0,
      isEnable: isEnableValue,
      priority,
      isFixedQuota = false,
      usedQuotaCache = [],
      currentBookingCount,
    } = item;

    const { code: carParkCode = 'N/A' } = CarPark || {};
    const { name: carType = 'N/A' } = CarType || {};
    const { type: parkingService = 'N/A' } = ParkingService || {};
    const isEnable = isEnableValue ? 'Yes' : 'No';

    const currentMonthly = moment().month() + 1;
    const currentYear = moment().year();
    const targetIndex = (usedQuotaCache || []).findIndex((e) => e.month === currentMonthly);

    const stringUsedQuotaCacheArray = Array.from({ length: 3 })
      .map((e, i) => {
        const index = (targetIndex === -1 ? 3 : targetIndex) + i;
        const month = currentMonthly + i > 12 ? currentMonthly + i - 12 : currentMonthly + i;
        const year = currentMonthly + i > 12 ? currentYear + 1 : currentYear;
        const targetObject = (usedQuotaCache || [])[index] || {};
        const usedCount = targetObject.usedCount || 0;
        return `${month <= 9 ? `0${month}` : month}/${year}:${usedCount}\n`;
      })
      .toString()
      .replaceAll(',', '');

    return {
      id,
      carParkCode,
      parkingService,
      carType,
      subTitle,
      quota,
      amount,
      isEnable,
      priority,
      isFixedQuota: isFixedQuota ? 'Yes' : 'No',
      usedQuotaCache: <p style={{ margin: 0 }}>{stringUsedQuotaCacheArray}</p>,
      currentBookingCount,
    };
  });

  return { newDataSource };
}

export function cleanParkingServiceTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      type,
      chineseType,
      remark,
      chineseRemark,
      isEnable,
      isShowIcon,
      isIHourly,
      updatedAt,
      priority,
    } = item;

    return {
      id,
      type,
      chineseType,
      remark,
      chineseRemark,
      isEnable: isEnable ? 'Yes' : 'No',
      isShowIcon: isShowIcon ? 'Yes' : 'No',
      isIHourly: isIHourly ? 'Yes' : 'No',
      updatedAt: parseDate(updatedAt, undefined, 8),
      priority,
    };
  });

  return { newDataSource };
}

export async function cleanPaymentTableData(resource) {
  const { allPaymentMethods = [] } = (await getPaymentMethods()) || {};

  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      accountCode = 'N/A',
      amount = 0,
      paid = 0,
      status = 'N/A',
      refundAmount = 0,
      createdAt: createdAtValue,
      PaymentMethodId,
      Order,
      OrderId,
    } = item;

    const { Bookings } = Order || {};
    const [booking] = Bookings || [];
    const { Car, Customer } = booking || {};
    const { registrationMark = 'N/A' } = Car || {};
    const { phone: customerPhone = 'N/A' } = Customer || {};

    const createdAt = parseDate(createdAtValue, undefined, 8);

    const { label: paymentMethod = 'N/A' } =
      allPaymentMethods.find(({ value }) => value === PaymentMethodId) || {};

    return {
      id,
      accountCode,
      amount,
      paid,
      status: refundAmount ? (
        <span style={{ color: 'red' }}>
          {status}
          {'\n'}
          (refunded)
        </span>
      ) : (
        status
      ),
      refundAmount,
      createdAt,
      paymentMethod,
      orderId: OrderId,
      registrationMark,
      customerPhone,
    };
  });

  return { newDataSource };
}

export function cleanSevenElevenTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      file,
      line,
      data,
      account,
      transactionTime,
      amount,
      createdAt,
      updatedAt,
      transactionId,
      status,
    } = item;

    return {
      id,
      file,
      line,
      data,
      account,
      transactionTime: parseTime(transactionTime, 'YYYY-MM-DD HH:mm', 0),
      amount,
      createdAt: parseTime(createdAt, 'YYYY-MM-DD HH:mm', 8),
      updatedAt: parseTime(updatedAt, 'YYYY-MM-DD HH:mm', 8),
      transactionId,
      status,
    };
  });

  return { newDataSource };
}

export function cleanSkiDataTableData(resource, column) {
  const newDataSource = resource.data.data.map((item) => {
    const { id, action, Booking, Customer, status = 'N/A' } = item;
    const { phone = 'N/A' } = Customer || {};
    const { CarPark, Car } = Booking || {};
    const { code = 'N/A' } = CarPark || {};
    const { registrationMark = 'N/A' } = Car || {};

    return {
      id,
      action,
      customerPhone: phone,
      carParkCode: code,
      registrationMark,
      status,
      retry: column(id),
    };
  });

  return { newDataSource };
}

export function cleanSkiDataMonthlyParkerableData(resource, column, setIsLoading) {
  const newDataSource = resource.data.data.map((item) => {
    const data = Object.fromEntries(
      Object.entries(item).map(([key, value]) => {
        const targetValue = value || 'N/A';
        return [key, targetValue];
      })
    );
    return {
      ...data,
      'PP card': data.SerialNo,
      'CarPark code': data.UserTitle,
      LPN: data.UserSurname,
      Surename: data.UserDepartment,
      Phone: data.UserEmail,
      'Service plan': data.CustomerSurname,
      'Service start date': data.CardValidFrom,
      'Service end date': data.CardValidUntil,
      'Import Button': column(item, setIsLoading),
    };
  });

  return { newDataSource };
}

export function cleanTransactionTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      reference,
      receipt,
      amount,
      transactionTime: transactionTimeValue,
      billingAccount,
      status,
      createdAt: createdAtValue,
      updatedAt: updatedAtValue,
      Order,
      PaymentMethod,
    } = item;

    const { Bookings } = Order || {};
    const [booking] = Bookings || [];
    const { Car, ParkingPlan } = booking || {};
    const { registrationMark } = Car || {};
    const { CarPark } = ParkingPlan || {};
    const { code } = CarPark || {};
    const { name: paymentMethodName } = PaymentMethod || {};

    const transactionTime = parseTime(transactionTimeValue);
    const createdAt = parseDate(createdAtValue, undefined, 8);
    const updatedAt = parseDate(updatedAtValue, undefined, 8);

    return {
      id,
      reference,
      receipt,
      amount,
      transactionTime,
      billingAccount,
      status: capitalize(status),
      createdAt,
      updatedAt,
      paymentMethod: paymentMethodName,
      registrationMark,
      carParkCode: code,
    };
  });

  return { newDataSource };
}

// Reports
export function wrapExcelString(str) {
  return `=""${str}""`;
}

export function cleanOnlineTransactionTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      amount: paidAmount = 0,
      transactionTime,
      receipt = '',
      billingAccount = '',
      reference = '',
      PaymentMethod,
      Order,
    } = item;

    const { name: paymentMethod, isOnlineMethod } = PaymentMethod || {};
    const { Bookings } = Order || {};
    const [booking] = Bookings || [];
    const { CarPark, Car, validFrom, validTo, type: paymentService = 'N/A' } = booking || {};
    const { code: carPark = 'N/A' } = CarPark || {};
    const { registrationMark: lpn = 'N/A' } = Car || {};

    const paymentDate = parseDate(transactionTime, 'YYYY/MM/DD');
    const parkingServiceStartDate = parseDate(validFrom, 'YYYY/MM/DD', 8);
    const parkingServiceEndDate = parseDate(validTo, 'YYYY/MM/DD', 8);
    const time = parseTime(transactionTime);
    const transactionId = isOnlineMethod
      ? receipt || reference || ''
      : billingAccount || reference || '';

    return {
      id,
      paymentDate,
      time,
      transactionId,
      carPark,
      lpn,
      paidAmount,
      paymentMethod,
      paymentService,
      parkingServiceStartDate,
      parkingServiceEndDate,
    };
  });

  return { newDataSource };
}

export function cleanAccountReconciliationReportData(resource) {
  const newDataSource = resource.data.data.accountData.map((item) => {
    const {
      paymentDate: Payment_Date,
      dateTime: DateTime,
      transactionId: Transaction_Id,
      carParkCode: Car_Park,
      amount: Amount,
      paidBy: Paid_By,
      paymentDatetime: payment_datetime,
      webApp,
    } = item;

    return [
      Payment_Date,
      DateTime,
      wrapExcelString(Transaction_Id),
      Car_Park,
      Amount,
      Paid_By,
      payment_datetime,
      webApp,
    ];
  });
  newDataSource.unshift([
    'Payment_Date',
    'DateTime',
    'Transaction_Id',
    'Car_Park',
    'Amount',
    'Paid_By',
    'payment_datetime',
    'Web/App',
  ]);

  return newDataSource;
}

export function cleanOfflineTransactionTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      amount: paidAmount = 0,
      status: statusValue,
      transactionTime,
      PaymentMethod,
      Order,
      skidataExtendDate: skidataExtendDateValue = '2020-08-10',
    } = item;

    const { name } = PaymentMethod || {};
    const { Bookings } = Order || {};
    const [booking] = Bookings || [];
    const {
      CarPark,
      Car,
      ParkingPlan,
      accountCode,
      validFrom,
      validTo,
      type: paymentServiceValue = 'N/A',
    } = booking || {};
    const { code: carPark = 'N/A' } = CarPark || {};
    const { registrationMark: lpn = 'N/A' } = Car || {};
    const { amount: parkingServiceFee = 0 } = ParkingPlan || {};

    const d = new Date(transactionTime);
    d.setHours(d.getHours() - 16);

    const paymentDate = parseDate(d, 'YYYY/MM/DD');
    const time = parseTime(d);
    const parkingServiceStartDate = parseDate(validFrom, 'YYYY/MM/DD', 8);
    const parkingServiceEndDate = parseDate(validTo, 'YYYY/MM/DD', 8);
    const skidataExtendDate = parseDate(skidataExtendDateValue, 'YYYY/MM/DD');
    const paymentService = capitalize(paymentServiceValue);
    const balance = parseInt(parkingServiceFee, 10) - parseInt(paidAmount, 10);
    const status = capitalize(statusValue);
    const paymentMethod = name === '7-11' ? '7-Eleven' : name;

    return {
      id,
      paymentDate,
      time,
      accountCode: PaymentMethod.originalId === 4 ? `47401${accountCode}` : accountCode,
      carPark,
      lpn,
      paidAmount,
      paymentMethod,
      paymentService,
      parkingServiceFee,
      balance,
      status,
      parkingServiceStartDate,
      parkingServiceEndDate,
      skidataExtendDate,
    };
  });

  return { newDataSource };
}

export function cleanChangeLpnTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      id,
      requestDate: requestDateValue = '2020-08-01',
      time: timeValue = '2020-08-01',
      effectiveDate: effectiveDateValue = '2020-09-10',
      fee = 100,
    } = item;

    const requestDate = parseDate(requestDateValue);
    const time = parseTime(timeValue);
    const changeLpnMethod = 'N/A';
    const effectiveDate = parseDate(effectiveDateValue);
    const originalLpn = 'N/A';
    const newLpn = 'N/A';

    return {
      id,
      requestDate,
      time,
      changeLpnMethod,
      effectiveDate,
      originalLpn,
      newLpn,
      fee,
    };
  });

  return { newDataSource };
}

export function cleanBuyNewTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const { id, validFrom, validTo, Car, CarPark, Payment, PickUpLocation, Order } = item;

    const parkingServiceStartDate = parseDate(validFrom, undefined, 8);
    const parkingServiceEndDate = parseDate(validTo, undefined, 8);

    const { type: paymentService = 'N/A' } = Order || {};
    const { code: carPark = 'N/A' } = CarPark || {};
    const { registrationMark: lpn = 'N/A' } = Car || {};
    const { Transactions, PaymentMethod } = Payment || {};
    const { name: paymentMethod } = PaymentMethod || {};
    const [transaction] = Transactions || [];
    const { amount: paidAmount = 0, id: transactionId, transactionTime } = transaction || {};
    const { address: deliveryAddress } = PickUpLocation || {};

    const paymentDate = parseDate(transactionTime);
    const time = parseTime(transactionTime);

    return {
      id,
      paymentDate,
      time,
      paymentMethod,
      transactionId,
      paidAmount,
      paymentService: capitalize(paymentService),
      carPark,
      lpn,
      parkingServiceStartDate,
      parkingServiceEndDate,
      pickUpMethod: deliveryAddress ? 'Self Pick Up' : 'Courier',
      deliveryAddress,
    };
  });

  return { newDataSource };
}

export function cleanAccMpTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      CarParkId: id,
      Payment_Date,
      code: carPark,
      validMonth,
      Unit_Price: unitPrice = 'N/A',
      Quantity: quantity = 'N/A',
      Amount: amount = 'N/A',
    } = item;

    const paymentDate = parseDate(Payment_Date);
    const serviceStartDate = moment(validMonth, 'YYYY-MM').startOf('month').format('YYYY/MM/DD');
    const serviceExpiryDate = moment(validMonth, 'YYYY-MM').endOf('month').format('YYYY/MM/DD');

    return {
      id,
      carPark,
      paymentDate,
      serviceStartDate,
      serviceExpiryDate,
      unitPrice,
      quantity,
      amount,
    };
  });

  return { newDataSource };
}

export function waitingListTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const { CarPark = {}, ParkingPlan = {}, count = 0, total = 0 } = item;

    return {
      id: `${CarPark.id}${ParkingPlan.id}`,
      carparkCode: CarPark.code,
      carparkChineseName: CarPark.chineseName,
      carparkEnglishName: CarPark.name,
      carType: ParkingPlan.CarType.name,
      parkingService: ParkingPlan.subTitle,
      numberOfNewRecord: count,
      totalOfRecord: total,
    };
  });

  return { newDataSource };
}

export function cleanIParkTransactionTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      settlementDateTime,
      transactionDateTime,
      transactionId = 'N/A',
      merchantTrxRef = 'N/A',
      phone = 'N/A',
      carParkCode = 'N/A',
      lpn,
      timeIn,
      timeOut,
      status,
      parkingFee,
      freeParkingHour = 0,
      freeParkingAmount = 0,
      discountType = 'N/A',
      paidAmount,
      balance,
      paymentMethod = 'N/A',
      cardNumber,
      reference,
      remarks,
      origin,
      outstandingPaymentErrorCode = 'N/A',
      errorDescription = 'N/A',
    } = item;
    const duration =
      timeIn && timeOut ? moment.duration(moment(timeOut).diff(timeIn, 'm'), 'm') : null;

    return {
      settlementDate: moment(settlementDateTime).format('YYYY-MM-DD'),
      settlementTime: moment(settlementDateTime).format('HH:mm'),
      transactionDate: moment(transactionDateTime).isValid()
        ? moment(transactionDateTime).format('YYYY-MM-DD')
        : 'N/A',
      transactionTime: moment(transactionDateTime).format('HH:mm'),
      transactionId: transactionId || 'N/A',
      merchTranRef: merchantTrxRef,
      phone,
      CarPark: carParkCode || 'N/A',
      lpn,
      timeIn: moment(timeIn).format('YYYY-MM-DD HH:mm'),
      timeOut: moment(timeOut).format('YYYY-MM-DD HH:mm'),
      duration: duration
        ? `${duration.get('d')}D ${duration.get('h')}H ${duration.get('m')}M`
        : '-',
      Status: status,
      parkingFee,
      freeParkingHour: freeParkingHour || 0,
      freeParkingAmount: freeParkingAmount || 0,
      discountType: discountType || 'N/A',
      paidAmount,
      balance,
      paymentMethod: paymentMethod || 'N/A',
      'cardNumber / Reference Number': cardNumber || reference,
      Platform: origin,
      outstandingPaymentErrorCode,
      errorDescription,
      remarks,
    };
  });

  return { newDataSource };
}

export function cleanIParkCouponTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const {
      couponId,
      promotionId,
      promotionName = 'N/A',
      totalValue,
      outstandingValue,
      promotionType,
      memberPhoneNumber = 'N/A',
      createdDate,
      updatedDate,
      validityType,
      validDays = 'N/A',
      validFrom,
    } = item;

    return {
      couponId,
      promotionId,
      promotionName,
      totalValue,
      outstandingValue,
      promotionType,
      memberPhoneNumber,
      createdDate: moment(createdDate).format('YYYY-MM-DD HH:MM'),
      updatedDate: moment(updatedDate).format('YYYY-MM-DD HH:MM'),
      validityType,
      validDays,
      validFrom: moment(validFrom).format('YYYY-MM-DD HH:MM'),
    };
  });

  return { newDataSource };
}

export function cleanSummaryTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    // const { id, CarPark, Order } = item;

    const {
      code: carParkCode,
      lastConfirmDate: createdAt,
      'Paid BY 7-11': sevenEleven,
      'Paid BY CQM': cqm,
      'Paid BY FPS': fps,
      'Paid BY O!e Pay': olePay,
      'Paid by PPS': pps,
      'Paid by VISA/Master': visaMaster,
      'current i-monthly amount': currentAmount = 0,
      'current i-monthly quantity': currentQuantity = 0,
      'next i-monthly amount': nextAmount = 0,
      'next i-monthly quantity': nextQuantity = 0,
    } = item;

    // const { code: carParkCode = 'N/A' } = CarPark || {};
    // const { Payment } = Order || {};
    // const {
    // amount: oneTimeAmount = 0,
    // createdAt
    // } = Payment || {};

    const transactionDate = parseDate(createdAt, undefined, 8);

    // const currentQuantity = 0;
    // const currentAmount = 0;
    // const nextQuantity = 0;
    // const nextAmount = 0;

    // const monthlyServiceQ = 'Q';
    // const monthlyServiceA = 'A';
    // const discountApplied = 'No';

    const monthlyServiceQ =
      (parseInt(currentQuantity, 10) || 0) + (parseInt(nextQuantity, 10) || 0);
    const monthlyServiceA = (parseInt(currentAmount, 10) || 0) + (parseInt(nextAmount, 10) || 0);
    const discountApplied = 0;
    const hourlyParkingQuantity = 0;
    const hourlyParkingAmount = 0;
    const oneTimeQuantity = 0;
    const oneTimeAmount = 0;
    const changeRegMarkQuantity = 0;
    const changeRegMarkAmount = 0;
    // const totalReceipt = 0;
    // const totalRevenue = 0;
    // const visaMaster = 'N/A';
    // const pps = 'N/A';
    // const sevenEleven = 'N/A';
    // const cqm = 'N/A';
    // const fps = 'N/A';
    // const olePay = 'N/A';
    const totalReceipt =
      monthlyServiceQ + changeRegMarkQuantity + oneTimeQuantity + hourlyParkingQuantity;
    const totalRevenue =
      monthlyServiceA + hourlyParkingAmount + oneTimeAmount + changeRegMarkAmount;

    return {
      // id,
      transactionDate,
      carParkCode,
      currentQuantity,
      currentAmount,
      nextQuantity,
      nextAmount,
      monthlyServiceQ,
      monthlyServiceA,
      discountApplied,
      hourlyParkingQuantity,
      hourlyParkingAmount,
      oneTimeQuantity,
      oneTimeAmount,
      changeRegMarkQuantity,
      changeRegMarkAmount,
      totalReceipt,
      totalRevenue,
      visaMaster,
      pps,
      sevenEleven,
      cqm,
      fps,
      olePay,
    };
  });
  return { newDataSource };
}

export function cleanOperationTableData(resource) {
  console.log({ resource });
  const newDataSource = resource.data.data.map((item) => {
    const {
      carParkCode,
      lastPaymentDate,
      lastPaymentTime,
      paymentPlatform,
      prevRegistrationMark,
      registrationMark,
      transactionId,
      validFrom,
      validTo,
      vehicleType,
      parkingPlan,
      paymentMethod,
      monthlyFee,
      paidAmount,
      paymentStatus,
      balance,
      orderType,
      paymentId,
      refundAmount,
      bookingStatus,
    } = item;

    // const lastPaymentDate = parseDate(beforelastPaymentDate);
    // const validFrom = parseDate(beforevalidFrom);
    // const validTo = parseDate(beforevalidTo);

    return {
      carParkCode,
      lastPaymentDate,
      lastPaymentTime,
      paymentPlatform,
      originalRegistrationMark:
        prevRegistrationMark === registrationMark ? '' : prevRegistrationMark,
      registrationMark,
      transactionId,
      validFrom,
      validTo,
      vehicleType,
      parkingPlan,
      paymentMethod,
      monthlyFee,
      paidAmount,
      paymentStatus: refundAmount ? (
        <span style={{ color: 'red' }}>
          {paymentStatus}
          {'\n'}
          (refunded)
        </span>
      ) : (
        paymentStatus
      ),
      balance,
      orderType,
      paymentId,
      bookingStatus,
    };
  });

  return { newDataSource };
}

export function cleanAdminTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const { id, name, isEnable, AdminRoleId, AdminRole = {}, UserId, User = {} } = item;

    return {
      id,
      name,
      isEnable: !!isEnable,
      enabled: isEnable ? 'Y' : 'N',
      AdminRoleId,
      adminRole: AdminRole.name || 'N/A',
      UserId,
      username: User.username || 'N/A',
    };
  });

  return { newDataSource };
}

export function cleanPaymentMethodTableData(resource) {
  const newDataSource = resource.data.data.map((item) => {
    const { id, name, isEnable, isOnlineMethod } = item;

    return {
      id,
      name,
      isEnable: !!isEnable,
      enabled: isEnable ? 'Y' : 'N',
      isOnlineMethod: !!isOnlineMethod,
      onlineMethod: isOnlineMethod ? 'Y' : 'N',
    };
  });

  return { newDataSource };
}

export function useRefund({ setIsLoading, cookies, isLoading }) {
  const [confirmRefundModal, setConfirmRefundModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refundData, setRefundData] = useState({ data: [], id: '' });
  const [selectedPayment, setSelectedPayment] = useState({});

  function fetchRefundablePayment(id) {
    setIsLoading(true);
    return axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/transaction?PaymentId=${id}&refundable=true`, {
        headers: { token: cookies.get('token') },
      })
      .then((e) => {
        console.log(e);
        setIsLoading(false);
        setRefundData({
          id,
          data: e.data.data.map(
            ({
              id: transactionId,
              PaymentId: paymentId,
              amount,
              reference,
              transactionTime,
              billingAccount,
              status,
              createdAt,
              updatedAt,
              PaymentMethod: { name: PaymentMethod },
              Order: { Bookings },
            }) => ({
              transactionId,
              paymentId,
              amount,
              reference,
              transactionTime: transactionTime
                ? moment(transactionTime).utcOffset(8).format('YYYY-MM-DD HH:mm')
                : 'N/A',
              billingAccount,
              status,
              createdAt: createdAt
                ? moment(createdAt).utcOffset(8).format('YYYY-MM-DD HH:mm')
                : 'N/A',
              updatedAt: updatedAt
                ? moment(updatedAt).utcOffset(8).format('YYYY-MM-DD HH:mm')
                : 'N/A',
              PaymentMethod,
              CarParkCode: Bookings[0].code,
              LPN: Bookings[0].Car.registrationMark,
            })
          ),
        });
        setShowModal(true);
      })
      .catch((err) => {
        setRefundData({ ...refundData, data: [] });
        setIsLoading(false);
        setShowModal(true);
      });
  }

  const renderRefundButton = (id) => (
    <button
      type="button"
      className="btn btn-primary btn-xs mr-3"
      onClick={() => {
        fetchRefundablePayment(id);
      }}
    >
      Refund
    </button>
  );

  function handelRefundButton(id, record) {
    return (
      <button
        type="button"
        className="btn btn-primary btn-xs mr-3"
        onClick={() => {
          setSelectedPayment(record);
          setRefundData({ ...refundData, id });
          setConfirmRefundModal(true);
        }}
      >
        Refund
      </button>
    );
  }

  function RefundableTable() {
    return (
      <>
        <Modal
          onCancel={() => setShowModal(false)}
          title="Refundable Transactions"
          visible={showModal}
          centered
          footer={null}
          width="80%"
          afterClose={() => setRefundData({ data: [], id: '' })}
        >
          <AntTable
            className="w-100"
            columns={generateTableColumns(
              [
                'transactionId',
                'paymentId',
                'amount',
                'reference',
                'transactionTime',
                'billingAccount',
                'status',
                'createdAt',
                'updatedAt',
                'PaymentMethod',
                'CarParkCode',
                'LPN',
              ],
              null,
              null,
              (id, record, index) => handelRefundButton(id, record, index)
            )}
            rowKey="id"
            dataSource={refundData.data}
            loading={isLoading}
            scroll={{ x: true }}
          />
        </Modal>

        <Modal
          title="Confirm Refund"
          visible={confirmRefundModal}
          onOk={() => {
            axios
              .post(
                `${process.env.REACT_APP_API_DOMAIN}/transaction/refund/${selectedPayment.transactionId}`,
                {
                  headers: { token: cookies.get('token') },
                }
              )
              .then((e) => {
                setConfirmRefundModal(false);
                fetchRefundablePayment(refundData.id);
                alert('Payment successfully refunded');
              })
              .catch((err) => {
                setConfirmRefundModal(false);
                alert(err);
              });
          }}
          okText="Confirm"
          onCancel={() => {
            setConfirmRefundModal(false);
          }}
          afterClose={() => setSelectedPayment({})}
          centered
        >
          {`Are you sure to refund the transaction Id ${selectedPayment.transactionId} of amount $${selectedPayment.amount}?`}
        </Modal>
      </>
    );
  }

  return {
    renderRefundButton,
    RefundableTable,
  };
}
