import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Table, Button, Collapse } from 'reactstrap';
import { Spin } from 'antd';
import Cookies from 'universal-cookie/cjs';
import axios from 'axios';
import {
  cleanRelationData,
  convertLabelFormat,
  convertDateFormat,
  getStringToNestedObject,
} from '../../../util/generalHelper';
import { cleanDefaultDetails } from '../../../util/detailsHelper';
import CONFIG from '../../../config';

const cookies = new Cookies();

function DefaultDetails({ fetch, edit, back, customSchema, customCleanData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState([]);
  const [details, setDetails] = useState({});
  const [relationType, setRelationType] = useState({});
  const [relationSchema, setRelationSchema] = useState({});
  const [relationData, setRelationData] = useState({});
  const [collapsed, setCollapsed] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(fetch, {
        headers: { token: cookies.get('token') },
      })
      .then(async (response) => {
        let cleaned;
        const {
          data: { data },
        } = response;

        if (customCleanData === undefined) {
          cleaned = cleanDefaultDetails(response);

          setSchema(cleaned.cleanedSchema);
          setDetails(cleaned.dataSource);
          setIsLoading(false);

          cleaned.relations.forEach(({ value, title }) => {
            getRelationalData(value, title);
          });
        } else {
          cleaned = await customCleanData(data);

          setSchema(customSchema);
          setDetails(cleaned);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert('Error loading page');
        console.log(error);
      });
  }, []);

  const getRelationalData = (value, title) => {
    axios
      .get(`${fetch}/${value}`)
      .then((response) => {
        const { cleanedRelationalData } = cleanRelationData(
          response.data.schema,
          response.data.data,
          value,
          title,
          'details'
        );

        setRelationType({
          ...relationType,
          ...cleanedRelationalData.relationType,
        });
        setRelationSchema({
          ...relationSchema,
          ...cleanedRelationalData.relationSchema,
        });
        setRelationData({
          ...relationData,
          ...cleanedRelationalData.relationData,
        });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const renderData = (type, value, inputData, displayZero) => {
    switch (type) {
      case 'DATE':
        return <span>{inputData[value] ? convertDateFormat(inputData[value]) : 'N/A'}</span>;
      case 'radio':
      case 'BOOLEAN':
        return inputData[value] ? <span>Yes</span> : <span>No</span>;
      case 'JSONTYPE':
        return <span>N/A</span>;
      case 'STRING':
        if (value.toLowerCase().includes('remark')) {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: inputData[value] ? inputData[value] : 'N/A' }}
            />
          );
        }
        return <span>{inputData[value] ? inputData[value] : 'N/A'}</span>;
      default:
        return value === 'image' || value === 'icon' ? (
          <>
            <span>{inputData[value] || 'N/A'}</span>
            {inputData[value] && (
              <div
                style={{
                  marginTop: 10,
                  background: '#e9ecef',
                  border: '1px solid #ced4da',
                  borderRadius: 5,
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  maxHeight: 150,
                }}
              >
                <img
                  src={`${CONFIG.CP_MEDIA_DOMAIN}${inputData[value]}`}
                  style={{ objectFit: 'contain' }}
                  alt={inputData[value]}
                />
              </div>
            )}
          </>
        ) : (
          <span>{inputData[value] ? inputData[value] : displayZero ? 0 : 'N/A'}</span>
        );
    }
  };

  const renderDetails = (labels, inputData) => (
    <Row>
      {labels.map(({ label, value, type, displayZero = false }, index) => (
        <Col className="d-flex flex-column mb-5" md={6} key={index}>
          <Label style={{ fontWeight: 600 }}>{label}</Label>
          <span style={{ fontSize: '120%' }}>
            {renderData(type, value, inputData, displayZero)}
          </span>
        </Col>
      ))}
    </Row>
  );

  const renderRelationTables = () => (
    <>
      {Object.keys(relationType).map((type) => (
        <div key={type}>
          <Button
            color="light"
            style={{
              marginBottom: '1rem',
              textAlign: 'left',
              width: '100%',
            }}
            onClick={() => {
              const isCollapsed = collapsed.includes(type)
                ? collapsed.filter((existingType) => existingType !== type)
                : [...collapsed, type];

              setCollapsed(isCollapsed);
            }}
          >
            <span>
              &#9660; &nbsp;
              {relationType[type]}
            </span>
          </Button>

          <Collapse
            isOpen={!!collapsed.includes(type)}
            style={{ marginBottom: 50, overflowX: 'auto' }}
          >
            <Table striped>
              <thead>
                <tr>
                  {relationSchema[type].map((schema) => (
                    <th scope="col" key={schema.value}>
                      {schema.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(relationData[type]) &&
                  relationData[type].map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.keys(row).map((field) => {
                        const data = row[field] !== null ? row[field] : 'N/A';
                        return <td key={field}> {data}</td>;
                      })}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Collapse>
        </div>
      ))}
    </>
  );

  const labels = convertLabelFormat(schema);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end mb-4">
            {edit && (
              <button className="btn btn-primary btn-sm mr-3" onClick={() => edit && edit()}>
                Edit
              </button>
            )}
            <button className="btn btn-primary btn-sm" onClick={() => back && back()}>
              Back
            </button>
          </div>

          {renderDetails(labels, details)}
          {renderRelationTables()}
        </>
      )}
    </>
  );
}

export default DefaultDetails;
