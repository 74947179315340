import _, { capitalize } from 'lodash';
import moment from 'moment';
import {
  getCustomerById,
  getCustomerLocation,
  getLocationById,
  getPaymentMethodById,
  getPPCards,
} from './generalHelper';
import { parseDate } from './tableHelper';

export function cleanDefaultDetails(response, requiredDateFields = [], hiddenField = []) {
  const { schema } = response.data;
  const resData = response.data.data ? response.data.data : [];
  const relations = response.data.relations
    ? response.data.relations.map((value) => {
        const title = _.lowerCase(value)
          .split(' ')
          .map((word) => _.capitalize(word))
          .join(' ');
        return { title, value };
      })
    : [];

  const cleanedSchema = schema.filter(
    ({ type, name }) =>
      (requiredDateFields.includes(name) || (type !== 'DATE' && name !== 'password')) &&
      !hiddenField.includes(name)
  );

  const filteredSchema = cleanedSchema.map(({ name }) => name);

  const dataSource = Object.entries(resData).reduce((output, [key, value]) => {
    if (filteredSchema.includes(key)) {
      output[key] = value;
    }
    return output;
  }, {});

  return { cleanedSchema, dataSource, relations };
}

export async function cleanBookingDetails(data, isForm) {
  const {
    Car,
    CustomerId,
    PPCardId,
    ParkingPlan,
    validFrom: validFromValue,
    validTo: validToValue,
    type: typeValue,
  } = data;

  const { id: carId, registrationMark: regMarkValue } = Car || {};
  const { CarPark, ParkingService, subTitle, id } = ParkingPlan || {};
  const { id: carParkId, code } = CarPark || {};
  // const {  type: parkingServiceType = 'N/A' } = ParkingService || {};

  const plan = `${subTitle}`;

  const [customerPhone, { allPPCards }] = await Promise.all([
    getCustomerById(CustomerId),
    getPPCards(),
  ]);

  let ppCardNumberValue;
  if (PPCardId) {
    const ppCard = allPPCards.find(({ value }) => value === PPCardId);
    const { label } = ppCard || {};
    ppCardNumberValue = label;
  }

  const carParkCode = isForm ? { value: carParkId, label: code } : code;
  const parkingPlan = isForm ? { value: id, label: plan } : plan;
  const registrationMark = isForm ? { value: carId, label: regMarkValue } : regMarkValue;
  const customer = isForm ? { value: customerPhone, label: customerPhone } : customerPhone;
  const ppCardNumber = isForm ? { value: PPCardId, label: ppCardNumberValue } : ppCardNumberValue;
  const type = isForm ? { value: typeValue, label: capitalize(typeValue) } : capitalize(typeValue);

  let validFrom;
  let validTo;

  if (isForm) {
    validFrom = validFromValue || null;
    validTo = validToValue || null;
  } else {
    validFrom = validFromValue ? parseDate(validFromValue) : null;
    validTo = validToValue ? parseDate(validToValue) : null;
  }

  return {
    carParkCode,
    parkingPlan,
    validFrom,
    validTo,
    registrationMark,
    type,
    customer,
    ppCardNumber,
  };
}

export async function cleanCarDetails(data, isForm) {
  const {
    id,
    CustomerId,
    registrationMark,
    CarType,
    isActivate,
    isEnable,
    activateCard,
    activateCardNumber,
  } = data;
  const { id: carTypeId, name } = CarType || {};

  const customerPhone = await getCustomerById(CustomerId);

  const phone = isForm ? { value: customerPhone, label: customerPhone } : customerPhone;
  const carType = isForm ? { value: carTypeId, label: name } : name;
  const activateCardType = isForm ? { value: activateCard, label: activateCard } : activateCard;

  return {
    id,
    customerPhone: phone,
    isActivate,
    isEnable,
    registrationMark,
    carType,
    activateCardType,
    activateCardNumber,
  };
}

export async function cleanCarParkDetails(data) {
  const { id, facilityNo, code, name, chineseName, isIMonthly, onlineShow, updatedAt, LocationId } =
    data;

  const locationName = await getLocationById(LocationId);

  return {
    id,
    facilityNo,
    code,
    name,
    chineseName,
    isIMonthly,
    onlineShow,
    updatedAt,
    location: locationName,
  };
}

export async function cleanCustomerDetails(data, isForm) {
  const {
    MajorCustomerId,
    id,
    originalId,
    title: titleValue,
    lastName,
    firstName,
    chineseLastName,
    chineseFirstName,
    email,
    phone,
    status: statusValue,
    language: languageValue = 'en',
    isEmailVerified,
    address,
    isReceiveSms,
    isReceivePromotion,
    isAgreedToTerms,
    isAgreedToPrivacy,
    isAgreedToNotice,
    isMajor,
  } = data;

  const title = isForm ? { value: titleValue, label: titleValue } : titleValue;
  const status = isForm ? { value: statusValue, label: statusValue } : statusValue;

  const convertedLanguage = languageValue.includes('en') ? 'en' : 'zh';

  const languageType = convertedLanguage === 'zh' ? 'Chinese' : 'English';
  const language = isForm ? { value: convertedLanguage, label: languageType } : languageType;

  const {
    locationId,
    region: regionValue,
    district: districtValue,
  } = await getCustomerLocation(id);

  const region = isForm ? { value: regionValue, label: regionValue } : regionValue;
  const district = isForm ? { value: locationId, label: districtValue } : districtValue;

  return {
    MajorCustomerId,
    id,
    originalId,
    title,
    lastName,
    firstName,
    chineseLastName,
    chineseFirstName,
    email,
    phone,
    status,
    language,
    isEmailVerified,
    address,
    isReceiveSms,
    isReceivePromotion,
    isAgreedToTerms,
    isAgreedToPrivacy,
    isAgreedToNotice,
    isMajor,
    region,
    district,
  };
}

export function cleanParkingPlanDetails(data, isForm) {
  const {
    CarPark,
    CarType,
    chineseSubTitle,
    skidataSurname,
    timeOfDay,
    id,
    ParkingService,
    amount,
    isEnable,
    quota,
    subTitle,
    priority,
    isFixedQuota = false,
    isWaitingList,
    usedQuotaCache = [],
  } = data;

  const { id: carParkId, code } = CarPark || {};
  const { id: carTypeId, name } = CarType || {};
  const { id: parkingServiceId, type } = ParkingService || {};

  const carParkCode = isForm ? { value: carParkId, label: code } : code;
  const parkingService = isForm ? { value: parkingServiceId, label: type } : type;
  const carType = isForm ? { value: carTypeId, label: name } : name;

  const currentMonthly = moment().month() + 1;
  const currentYear = moment().year();
  const targetIndex = (usedQuotaCache || []).findIndex((e) => e.month === currentMonthly);

  const usedQuotaCacheArray = Array.from({ length: 3 }).map((e, i) => {
    const index = (targetIndex === -1 ? 3 : targetIndex) + i;
    const month = currentMonthly + i > 12 ? currentMonthly + i - 12 : currentMonthly + i;
    const year = currentMonthly + i > 12 ? currentYear + 1 : currentYear;
    const targetObject = (usedQuotaCache || [])[index] || {};
    const usedCount = targetObject.usedCount || 0;
    return { label: `${month <= 9 ? `0${month}` : month}/${year}`, value: usedCount };
  });

  return {
    id,
    chineseSubTitle,
    skidataSurname,
    timeOfDay,
    carParkCode,
    parkingService,
    carType,
    subTitle,
    quota,
    amount,
    isEnable,
    priority,
    isFixedQuota,
    isWaitingList,
    usedQuotaCacheArray,
  };
}

export function cleanParkingServiceDetails(data) {
  const {
    id,
    type,
    chineseType,
    remark,
    chineseRemark,
    isEnable,
    updatedAt,
    priority,
    isShowIcon,
    isIHourly,
    isSearchParam,
  } = data;

  return {
    id,
    type,
    chineseType,
    remark,
    chineseRemark,
    isEnable,
    updatedAt,
    priority,
    isShowIcon,
    isIHourly,
    isSearchParam,
  };
}

export async function cleanPaymentDetails(data, isForm) {
  const {
    id,
    accountCode,
    amount,
    status: statusValue,
    PaymentMethodId,
    remark,
    refundAmount,
    refundDate,
  } = data;

  const paymentMethodName = await getPaymentMethodById(PaymentMethodId);

  const status = isForm
    ? { value: statusValue, label: capitalize(statusValue) }
    : capitalize(statusValue);

  const paymentMethod = isForm
    ? { value: PaymentMethodId, label: paymentMethodName }
    : paymentMethodName;

  return {
    id,
    accountCode,
    amount,
    status,
    paymentMethod,
    remark,
    refundAmount,
    refundDate,
  };
}

export function cleanPPCardDetails(data, isForm) {
  const { id, cardNumber: cardNumberValue, status: statusValue } = data;

  const cardNumber = isForm ? { value: id, label: cardNumberValue } : cardNumberValue;
  const status = isForm ? { value: statusValue, label: capitalize(statusValue) } : statusValue;

  return {
    id,
    cardNumber,
    status,
  };
}

export function cleanTransactionDetails(data, isForm) {
  const {
    id,
    reference,
    receipt,
    amount,
    billingAccount,
    status: statusValue,
    PaymentMethod,
    Order,
    remarks,
    transactionTime,
  } = data;

  const { Bookings } = Order || {};
  const [booking] = Bookings || [];
  const { Car, ParkingPlan } = booking || {};

  const { registrationMark } = Car || {};

  // const { phone } = Customer || {};

  const { CarPark } = ParkingPlan || {};
  const { id: carParkId, code } = CarPark || {};
  const { id: paymentMethodId, name } = PaymentMethod || {};

  const carParkCode = isForm ? { value: carParkId, label: code } : code;
  const paymentMethod = isForm ? { value: paymentMethodId, label: name } : name;
  const status = isForm
    ? { value: statusValue, label: capitalize(statusValue) }
    : capitalize(statusValue);

  return {
    id,
    reference,
    receipt,
    amount,
    billingAccount,
    status,
    paymentMethod,
    // customerPhone: phone,
    registrationMark,
    carParkCode,
    remarks,
    transactionTime,
  };
}

export function cleanAdminDetails(data, isForm) {
  const { id, name, isEnable, AdminRoleId, AdminRole = {}, UserId, User = {} } = data;

  return {
    id,
    name,
    isEnable: !!isEnable,
    AdminRoleId,
    UserId,
    username: User.username,
    email: User.email,
    phone: User.phone,
    role: AdminRole.name,
  };
}

export function cleanBannerData(response) {
  const { schema } = response.data;
  const resData = response.data.data ? response.data.data : [];
  const relations = response.data.relations
    ? response.data.relations.map((value) => {
        const title = _.lowerCase(value)
          .split(' ')
          .map((word) => _.capitalize(word))
          .join(' ');
        return { title, value };
      })
    : [];

  const cleanedSchema = schema.filter(
    ({ type, name }) =>
      name === 'effectiveFrom' || name === 'effectiveTo' || (name !== 'password' && type !== 'DATE')
  );

  const filteredSchema = cleanedSchema.map(({ name }) => name);

  const dataSource = Object.entries(resData).reduce((output, [key, value]) => {
    if (filteredSchema.includes(key)) {
      output[key] = value;
    }
    return output;
  }, {});

  return { cleanedSchema, dataSource, relations };
}

export async function cleanWaitingListData(data) {
  const {
    id,
    status,
    reference,
    priority,
    CarPark = {},
    ParkingPlan = {},
    Customer = {},
    Car = {},
    createdAt,
    manualInputDate,
    remarks,
  } = data || {};

  const { code = '', name = '' } = CarPark || {};
  const { subTitle = '' } = ParkingPlan || {};
  const { firstName = '', lastName = '' } = Customer || {};
  const { registrationMark = '' } = Car || {};

  return {
    id,
    status,
    reference,
    priority: priority || 'N/A',
    CarPark: `(${code || ''}) ${name || ''}`,
    parkingPlan: subTitle,
    customer: `${firstName || ''} ${lastName || ''}`,
    car: registrationMark,
    createdAt: moment(createdAt).format('YYYY-MM-DD HH:mm'),
    manualInputDate: manualInputDate ? moment(manualInputDate).format('YYYY-MM-DD HH:mm') : 'N/A',
    remarks,
  };
}
